// Bootstrap brand color customization


/*     brand Colors              */

$brand-primary:              #B51621 !default;
$brand-info:                 $cyan-500 !default;
$brand-success:              $green-500 !default;
$brand-warning:              $orange-500 !default;
$brand-danger:               $red-500 !default;
$brand-rose:                 $pink-500 !default;
$brand-inverse:              $black-color !default;

$primary: $brand-primary;
$bg-primary: $brand-primary;
$bg-gray: $brand-primary;
body{
    
    transition:  .5s;
}
.bg-primary{
    fill: $brand-primary;
}
.bg-gray{
    fill: $gray;
}
.dark-edition{

    .bg-primary{
        fill: rgb(177, 177, 177);
    }
    .bg-gray{
        fill:  rgb(177, 177, 177);
    }
}
$form-feedback-valid-color:         $brand-primary !default;
//$form-feedback-invalid-color:      $gray  !default;

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $brand-primary;
}